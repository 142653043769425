import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index';
import home from '@/views/home';
import about from '@/views/about';
import profession from '@/views/profession';
import solution from '@/views/solution';
import team from '@/views/team';
import newsDetail from '@/views/newsDetail';

import indexM from '@/views/h5/index';
import homeM from '@/views/h5/home';
import aboutM from '@/views/h5/about';
import professionM from '@/views/h5/profession';
import solutionM from '@/views/h5/solution';
import teamM from '@/views/h5/team';
import newsDetailM from '@/views/h5/newsDetail';

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
        redirect: '/home',
        children: [
            {path: '/home', name: '首页', component: home, meta: {title: "智运通|顺风大运-首页", content: 'disable'}},
            {path: '/profession', name: '专业', component: profession, meta: {title: "智运通|顺风大运-专业", content: 'disable'}},
            {path: '/about', name: '联系我们', component: about, meta: {title: "智运通|顺风大运-联系我们", content: 'disable'}},
            {path: '/solution', name: '解决方案', component: solution, meta: {title: "智运通|顺风大运-解决方案", content: 'disable'}},
            {path: '/team', name: '菁英团队', component: team, meta: {title: "智运通|顺风大运-菁英团队", content: 'disable'}},
            {
                path: '/newsDetail/:id',
                name: '新闻详情',
                component: newsDetail,
                meta: {title: "智运通|顺风大运-新闻详情", content: 'disable'}
            },
        ]
    },

    {
        path: '/m',
        name: 'm',
        component: indexM,
        redirect: '/m/home',
        children: [
            {path: '/m/home', name: '首页', component: homeM, meta: {title: "智运通|顺风大运-首页", content: 'disable'}},
            {path: '/m/profession', name: '专业', component: professionM, meta: {title: "智运通|顺风大运-专业", content: 'disable'}},
            {path: '/m/about', name: '关于我们', component: aboutM, meta: {title: "智运通|顺风大运-联系我们", content: 'disable'}},
            {path: '/m/solution', name: '解决方案', component: solutionM, meta: {title: "智运通|顺风大运-解决方案", content: 'disable'}},
            {path: '/m/team', name: '菁英团队', component: teamM, meta: {title: "智运通|顺风大运-菁英团队", content: 'disable'}},
            {
                path: '/m/newsDetail/:id',
                name: '新闻详情',
                component: newsDetailM,
                meta: {title: "智运通|顺风大运-新闻详情", content: 'disable'}
            },
        ]
    }
]
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }
const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
